/////////////////////////////////////////
// COmponents
/////////////////////////////////////////
const svg4everybody = require('svg4everybody');
import Swiper from 'swiper';
/////////////////////////////////////////
// APP
/////////////////////////////////////////
window.app = {

	page: document.body.id, // store the current page name
	queryParam: 'jgDonationId', // set which query param to look for

	// Store the gallery sliders
	previews: {
		gals: [],
		thumbs: [],
	},

	/////////////////////////////////////////
	// START UP PLAY
	/////////////////////////////////////////
	init: () => {
		console.log('HELLO APP.JS');

		// inline SVGS
		svg4everybody({
			polyfill: true // polyfill <use> elements for External Content
		});

		// Stop here if donation page
		if (app.page == 'page-donate') return;

		// Do only on MAIN page
		if (app.page == 'page-main') {
			app.donationCheckQuery();

			// Setup all the Slider
			let $previews = document.querySelectorAll('.packPreview');
			$previews.forEach(($this, i) => {
				app.slidersSetupAll($this, i);
			});

			// TODO: SETUP ALL THE MODALS?
		}

		// Listen for DOM events
		app.bindEvents();

	},

	/////////////////////////////////////////
	// DOM EVENTS
	/////////////////////////////////////////
	bindEvents: () => {

	},

	/////////////////////////////////////////
	// Donation Checking Functions
	/////////////////////////////////////////

	//:::::::::::::::::::::::::::::::::::::::
	// Check for url query
	//:::::::::::::::::::::::::::::::::::::::
	donationCheckQuery: () => {

		// get the window url and split of the ?query
		const query = window.location.href.slice(window.location.href.indexOf('?') + 1);

		// TEST QUERY
		if (query.indexOf("jgDonationId") == -1) {
			// No Query Found...
			app.switchDonateMode();
		} else {
			// Query Found...
			// get the donation ID form the query string
			let donationId = query.split('=');
			donationId = donationId[1];
			app.donationCheckApi(donationId); // check donation against api
		}

	},

	//:::::::::::::::::::::::::::::::::::::::
	// Check for donation
	//:::::::::::::::::::::::::::::::::::::::
	donationCheckApi: (donationId) => {

		// req to api
		const request = new XMLHttpRequest();
		request.open("GET", "https://api.justgiving.com/bbd577e0/v1/donation/" + donationId + "/status", false);
		request.send();
		// alert(request.status);

		// Check req status
		if (request.status != 200) {
			// req error...
			app.switchDonateMode();
		} else {

			// res recieved..
			const res = request.responseXML;
			console.log(res);
			app.switchDownloadMode();
		}

	},

	/////////////////////////////////////////
	// Switching between Donate/Download modes
	/////////////////////////////////////////

	//:::::::::::::::::::::::::::::::::::::::
	// Switch to Donate mode
	//:::::::::::::::::::::::::::::::::::::::
	switchDonateMode: () => {

		// elemnts to show/hide
		const $show = document.querySelectorAll('.js-showOnDonate');
		const $hide = document.querySelectorAll('.js-showOnDownload');

		$show.forEach(($this, i) => {
			$this.classList.remove('is-disabled');
		});

		$hide.forEach(($this, i) => {
			$this.classList.add('is-disabled');
		});
	},

	//:::::::::::::::::::::::::::::::::::::::
	// Switch TO downlaod mode
	//:::::::::::::::::::::::::::::::::::::::
	switchDownloadMode: () => {

		// elemnts to show/hide
		const $show = document.querySelectorAll('.js-showOnDownload');
		const $hide = document.querySelectorAll('.js-showOnDonate');

		$show.forEach(($this, i) => {
			$this.classList.remove('is-disabled');
		});

		$hide.forEach(($this, i) => {
			$this.classList.add('is-disabled');
		});


		// Add download buttons to packs
		const $packs = document.querySelectorAll('.pack');
		$packs.forEach(($pack, i) => {

			// find the zip file name
			let zip = $pack.getAttribute('data-zip');
			zip = zip + '.zip ';

			// Crete the button HTML
			const buttonHtml = `
				<li>
					<a class = "btn" href = "./packs/${zip}" download>
						DOWNLOAD
					</a>
				</li>
			`;

			// ADD the button to HTML
			const $btnList = $pack.querySelector('.pack__ctas');
			$btnList.innerHTML = $btnList.innerHTML + buttonHtml;
		});
	},

	/////////////////////////////////////////
	// Sliders
	/////////////////////////////////////////

	//:::::::::::::::::::::::::::::::::::::::
	// Setup All
	//:::::::::::::::::::::::::::::::::::::::
	slidersSetupAll: ($el, i) => {

		// Setup thumbnails slider
		app.previews.thumbs[i] = new Swiper('#packthumb-' + i, {
			// Optional parameters
			direction: 'horizontal',
			loop: true,
			slidesPerView: 3,
			centeredSlides: true,
			navigation: {
				nextEl: '#packNext-' + i,
				prevEl: '#packPrev-' + i,
			}
		});

		// setup main gallery view
		app.previews.gals[i] = new Swiper('#packgal-' + i, {
			// Optional parameters
			direction: 'horizontal',
			loop: true,
			thumbs: {
				swiper: app.previews.thumbs[i],
			},
			effect: 'fade',
		});

		// sync nextprev/btns
		app.previews.thumbs[i].on('slideChange', function () {
			let slide = app.previews.thumbs[i].realIndex;
			// console.log(slide);
			app.previews.gals[i].slideToLoop(slide, null, false);
		});

	},
};

/////////////////////////////////////////
// START
/////////////////////////////////////////
app = window.app;
app.init();